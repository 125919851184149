<template>
  <div
    class="relative w-full flex items-center flex-wrap px-3 border-b border-slate-500"
    :class="[
      size === 'lg' ? 'py-3 sm:py-4' : 'py-0.5',
      { 'bg-blue/20': item.isPlayer }
    ]"
  >
    <!-- Rank -->
    <div class="w-1/12 flex items-center relative z-10">
      <span class="font-bold leading-none text-xs sm:text-sm" :class="{ 'gradient-gold ': item.prizes.length }">
        {{ item.points ? item.rank : '&bull;' }}
      </span>
    </div>

    <!-- Player -->
    <div class="w-4/12 relative z-10">
      <NuxtLink :to="`/${item.displayName?.toLowerCase()}`" class="flex items-center mr-1 group">
        <UserAvatar
          :url="item.avatarUrl"
          :country="item.currentCountry ?? item.country"
          size="2xs"
          class="mr-1 border-2 rounded-full"
          :class="{ 'border-cyan': item.isPlayer, 'border-transparent': !item.isPlayer }"
        />
        <h3 class="whitespace-nowrap text-ellipsis overflow-hidden group-hover:text-gray-300 transition-colors text-[10px] sm:text-xs" :class="{ 'lg:text-sm lg:ml-1': placement === 'page', '!text-xs sm:!text-sm': size === 'lg' }">
          {{ item.displayName }}
        </h3>
      </NuxtLink>
    </div>

    <!-- Prize -->
    <div v-if="showPrize" class="w-5/12 relative z-10">
      <template v-if="item.prizes.length && item.points">
        <div
          v-for="(prize, index) in item.prizes"
          :key="index"
          class="flex items-center border-t border-slate-500 pt-1 mt-1 first-of-type:border-none first-of-type:pt-0 first-of-type:mt-0"
        >
          <figure v-if="prize.type === 'Tangible' && prize.imageUrl && prize.imageUrl !== ''" class="mr-1 rounded-[3px] relative overflow-hidden w-4 h-4 sm:w-5 sm:h-5 flex-none grow-0 shrink-0">
            <img
              class="block relative z-10 w-full object-cover rounded-[3px]"
              :src="$imgSrcResize(prize.imageUrl, 50, 50)"
              width="50"
              height="50"
              loading="lazy"
              decoding="async"
            >
          </figure>

          <div class="grow overflow-hidden">
            <div class="flex flex-col text-[10px] sm:text-xs" :class="{ 'lg:text-sm': placement === 'page', '!text-xs sm:!text-sm': size === 'lg' }">
              <h2 class="font-bold whitespace-nowrap text-ellipsis overflow-hidden leading-none">{{ prize.name }}</h2>
              <h2 v-if="prize.type !== 'Tangible'" class="flex items-center">
                <CurrencyIconImage v-if="!metadata?.convertPrizeAmount && prize.currencyCode.toUpperCase() !== 'USD'" :currency="prize.currencyCode ? prize.currencyCode : 'ETH'" class="h-3 mr-0.5 relative"/>
                <span class="font-bold leading-none">{{ formatPrizeValue(prize) }}</span>
              </h2>
            </div>
          </div>
          <div v-if="pointsLabel" class="uppercase font-bold text-[10px] text-gold whitespace-nowrap leading-none w-[90px]">
            {{ pointsLabel }}
          </div>
        </div>
      </template>
      <div v-else-if="pointsLabel" class="uppercase font-bold text-[10px] text-gold whitespace-nowrap leading-none flex justify-between">
        <div/>
        <div class="w-[90px]">
          {{ pointsLabel }}
        </div>
      </div>
    </div>

    <!-- Points -->
    <div class="w-2/12 flex items-center justify-end font-bold text-[10px] sm:text-xs relative z-10" :class="{ '!text-xs sm:!text-sm': size === 'lg', 'flex-1': !showPrize }">
      <transition name="points" mode="out-in">
        <span
          v-if="!metadata?.hideRunningPoints && item.runningPoints > 0 && item.runningPoints !== item.points"
          :key="item.runningPoints"
          class="text-slate-100 text-[9px] sm:text-xs mr-2 whitespace-nowrap"
          :class="{ 'lg:text-sm': placement === 'page' }"
        >
          <CurrencyIconImage
            v-if="metadata?.displayCurrency && metadata?.displayCurrency.toUpperCase() !== 'USD'"
            :key="metadata?.displayCurrency"
            class="inline-block h-[10px] w-[10px] relative -top-[1px] grayscale"
            :currency="metadata?.displayCurrency"
          />
          {{ runningPointsDisplay }}
        </span>
      </transition>

      <transition name="points" mode="out-in">
        <span :key="item.points" class="text-right self-end whitespace-nowrap" :class="{ 'lg:text-sm lg:min-w-[100px]': placement === 'page' }">
          <CurrencyIconImage
            v-if="metadata?.displayCurrency && metadata?.displayCurrency.toUpperCase() !== 'USD'"
            :key="metadata?.displayCurrency"
            class="inline-block h-[10px] w-[10px] relative -top-[1px] ml-2"
            :currency="metadata?.displayCurrency"
          />
          {{ pointsDisplay }}
        </span>
      </transition>
    </div>

    <!-- Progress background -->
    <div
      v-if="!metadata?.hideRunningPoints"
      class="w-full h-[1px] absolute z-10 left-0 -bottom-[1px] transition-all duration-1000 after:absolute after:right-0 after:w-[5px] after:h-[5px] after:rounded-full after:-top-[2px] after:border after:border-black/40"
      :class="[
        barWidth === 0 ? 'opacity-0' : '',
        item.isPlayer ? 'bg-cyan-500 after:bg-cyan-500' : 'bg-gray-400 after:bg-gray-400'
      ]"
      :style="`width: ${barWidth}%;`"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    default: null,
  },
  showProgress: {
    type: Boolean,
    default: false,
  },
  displayType: {
    type: String,
    default: null,
  },
  decimalPlaces: {
    type: Number,
    default: null,
  },
  metadata: {
    type: Object,
    default: null,
  },
  placement: {
    type: String,
    default: null,
  },
  size: {
    type: String,
    default: null,
  },
  showPrize: {
    type: Boolean,
    default: false,
  },
  roundingDecimals: {
    type: Number,
    default: null,
  },
});

const { $truncateNumber, $toBigNumber, $formatMoney, } = useNuxtApp();

const cryptoStore = useCryptoStore();
const { rates, } = storeToRefs(cryptoStore);

const pointsDisplay = computed(() => formatPoints(props.item.points));
const runningPointsDisplay = computed(() => formatPoints(props.item.runningPoints));
const barWidth = computed(() => {
  if (!props.showProgress || !props.item) { return 0; }

  const width = (props.item.runningPoints / props.item.points * 100).toFixed(2);
  return Math.min(width, 100);
});
const pointsLabel = computed(() => {
  if (!props.metadata?.pointLabels?.length) { return null; }

  const index = Math.min(props.item.points - 1, props.metadata.pointLabels.length - 1);
  const label = props.metadata.pointLabels[index];

  return label || null;
});

function formatPoints(points) {
  if (props.decimalPlaces > 0) {
    points = points / Math.pow(10, props.decimalPlaces);

    if (props.roundingDecimals !== undefined && points > (1 / Math.pow(10, props.roundingDecimals))) {
      points = $truncateNumber(points, props.roundingDecimals);
    }
  }

  if (props.metadata?.displayCurrency) {
    points = formatPointsDisplay(points);
  }

  if (props.displayType === 'Multiplier') {
    points = `x${points}`;
  }

  return points;
}

function formatPointsDisplay(points) {
  switch (props.metadata?.displayCurrency) {
    case 'USD': {
      const val = $toBigNumber(points).decimalPlaces(2).toNumber();

      if (val < 100) {
        return `${$formatMoney(val, 'USD', true)}`;
      }

      return `$${$truncateNumber(val, 0, true)}`;
    }
    case 'ETH':
      return points;
  }
}

function formatPrizeValue(prize) {
  const currencyCode = prize.currencyCode || 'ETH';
  const isUsdPrize = prize.currencyCode.toUpperCase() === 'USD';

  if ((!props.metadata?.convertPrizeAmount || !rates.value) && !isUsdPrize) {
    const prizeMultiplier = props.metadata?.prizeDisplayMultiplier || 1;
    const amount = prize.amount * prizeMultiplier;
    return $truncateNumber(amount, 0, true);
  }

  let prizeValueBn = $toBigNumber(prize.amount);
  if (!isUsdPrize) {
    const rateUsd = rates.value[currencyCode]?.USD || null;
    if (!rateUsd) { return prize.amount; }
    const rateUsdBn = $toBigNumber(rateUsd);
    prizeValueBn = prizeValueBn.multipliedBy(rateUsdBn);
  }

  const valUsd = prizeValueBn.decimalPlaces(2).toNumber();
  if (valUsd < 100) {
    return `${$formatMoney(valUsd, 'USD', true)}`;
  }

  return `$${$truncateNumber(valUsd, 0, true)}`;
}
</script>
